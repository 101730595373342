import { ActionTypes } from "../../libs/enums";

const GetAction_AnnouncementType = (announcementType) => {
  return {
    type: ActionTypes.GET_ANNOUNCEMENT_TYPE,
    payload: announcementType,
  };
};

export { GetAction_AnnouncementType };
