import { useEffect as UseEffect, useState as UseState } from "react";
import { connect } from "react-redux";
import styles from "./styles.module.css";
import Link from "next/link";
import {
  LanguageUid,
  Announcements,
  DateType,
  LanguageValueType,
} from "../../../../libs/enums";
import {
  ConvertToDateByType,
  GetNumberOfRecordsByScreenSize,
  IsNullOrEmpty,
} from "../../../../libs/utils";
import UseTranslation from "next-translate/useTranslation";
import { Update_Paging } from "../../../../redux/dispatch/paging";
import { Get_AnnouncementCount } from "../../../../redux/dispatch/getAnnouncementCount";
import { Get_AnnouncementType } from "../../../../redux/dispatch/getAnnouncementType";
import NoData from "../../../no_data/index";
import { useRouter } from "next/router";
import { GetLanguageResource } from "../../../../libs/utils";

const Announcement = (props) => {
  const {
    announcements,
    onGetAnnouncementCount,
    title,
    onUpdatePaging,
    getCustomServiceIsActive,
    onGetAnnouncementType,
    viewAllUrl,
    typeIsActive,
  } = props;

  const { t } = UseTranslation("GetLanguageResourceResponse");
  const router = useRouter();

  const [announcementType, setAnnouncementType] = UseState(
    IsNullOrEmpty(router.query.announcementType)
      ? 1
      : router.query.announcementType
  );
  const [today, setToday] = UseState([]);
  const [showCount, setShowCount] = UseState(0);
  const [screenWidth, setScreenWidth] = UseState(0);

  const addRouter = (announcementType) => {
    if (router.pathname == "/duyurular") {
      router.query.page =
        parseInt(router.query.announcementType) !== parseInt(announcementType)
          ? 1
          : router.query.page;
      router.query.announcementType = announcementType;
      router.push(router);
    }
  };

  const onChangeAnnouncementByType = (announcementType) => {
    setAnnouncementType(announcementType);
  };

  const GetAnnouncementByTop = (type = Announcements.Type.GENERAL.VALUE) => {
    let newList = announcements.filter((x) => x.languageResources.length > 0);

    if (!IsNullOrEmpty(router.query.announcementType)) {
      newList = newList && newList.filter((f) => f.type === announcementType);
    }

    if (IsNullOrEmpty(router.query.announcementType)) {
      newList = newList && newList.filter((f) => f.type === type);
    }

    let getAnnouncement =
      newList &&
      newList.map((item, index) => (
        <div className="col-3 col-t-4 col-tv-6" key={index}>
          <div className={styles.container}>
            <div className={styles.content}>
              {/* Start Date */}
              <div className={styles.datetime}>
              {item.type === Announcements.Type.BID.VALUE && (
                <>
                  <span className={styles.bidDate}>{t(LanguageUid.ANNOUNCEMENT_BID_DATE)}</span>
                </>
              )}
                
                <div className={styles.number}>
                  {ConvertToDateByType(DateType.DAY, item.publishDate)}
                </div>

                <div className={styles.month}>
                  {t(ConvertToDateByType(DateType.MONTH, item.publishDate))}
                </div>

                <div className={styles.year}>
                  {ConvertToDateByType(DateType.YEAR, item.publishDate)}
                </div>
              </div>
              {/* End Date */}

              <div className={styles.description}>
                <p>
                  {
                    GetLanguageResource(
                      item.languageResources,
                      LanguageValueType.TITLE
                    ).value
                  }
                </p>
              </div>
            </div>

            <div className={styles.readMore}>
              <Link
                href={`/duyurular/${
                  item &&
                  GetLanguageResource(
                    item.languageResources,
                    LanguageValueType.TITLE
                  ).slug
                }`}
              >
                <a>{t(LanguageUid.ANNOUNCEMENT_READ_MORE_BUTTON)}</a>
              </Link>
            </div>
          </div>
        </div>
      ));

    return (
      <div className={styles.list}>
        {getAnnouncement.length > 0 ? (
          getAnnouncement
        ) : (
          <div key={1} className={styles.noData}>
            <NoData />
          </div>
        )}
      </div>
    );
  };

  UseEffect(() => {
    if (router.query.announcementType) {
      setAnnouncementType(parseInt(router.query.announcementType));
    }

    if (router.query.pageSize) {
      showCount = parseInt(router.query.pageSize);
    }

    setShowCount(showCount);

    /**
     * Page > ShowCount > Start > End
     */
    onUpdatePaging(
      1,
      parseInt(showCount),
      0,
      GetNumberOfRecordsByScreenSize()[0]
    );

    announcements &&
      announcements.map((announcement) => {
        const date = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        );

        const getDate = new Date(announcement.publishDate);
        const newDate = new Date(
          getDate.getFullYear(),
          getDate.getMonth(),
          getDate.getDate()
        );

        if (date.getTime() === newDate.getTime()) {
          setToday((today) => [...today, announcement.type]);
        }
      });

    setScreenWidth(window.screen.width);
  }, []);

  UseEffect(() => {
    const announcementCount =
      announcements &&
      announcements.filter((f) => f.type === announcementType).length;

    onGetAnnouncementCount(announcementCount);

    onGetAnnouncementType(announcementType);

    var showCount = GetNumberOfRecordsByScreenSize()[0];

    if (router.query.pageSize) {
      showCount = parseInt(router.query.pageSize);
    }

    setShowCount(showCount);

    /**
     * Page > ShowCount > Start > End
     */
    onUpdatePaging(
      parseInt(router.query.page),
      parseInt(showCount),
      0,
      GetNumberOfRecordsByScreenSize()[0]
    );

    addRouter(announcementType);
  }, [announcementType]);

  return (
    <section className={styles.section}>
      {/* Start Title */}
      {title && (
        <h1
          className={
            typeIsActive
              ? styles.title
              : `${styles.title} ${styles.titleNoBottom}`
          }
        >
          {t(LanguageUid.ANNOUNCEMENT_TITLE)}
          <Link href={`/${viewAllUrl}`}>
            <a>
              <span>{t(LanguageUid.ANNOUNCEMENT_GET_ALL_GENERAL_BUTTON)}</span>
            </a>
          </Link>
        </h1>
      )}
      {/* End Title */}
      {/* Start Announcement Buttons for Filtered*/}
      {typeIsActive && (
        <div className={`btn-group ${styles.btnGroup}`}>
          <button
            style={{
              backgroundColor:
                announcementType === Announcements.Type.GENERAL.VALUE
                  ? "var(--pttYellowColor)"
                  : null,
            }}
            onClick={() =>
              onChangeAnnouncementByType(Announcements.Type.GENERAL.VALUE)
            }
          >
            {today.includes(Announcements.Type.GENERAL.VALUE) ? (
              <>
                <i className="fa-solid fa-bell"></i>
                <p>{t(LanguageUid.NEW_POST)}</p>
              </>
            ) : null}
            {t(LanguageUid.ANNOUNCEMENT_GENERAL)}
          </button>

          <button
            style={{
              backgroundColor:
                announcementType === Announcements.Type.PHILATELY.VALUE
                  ? "var(--pttYellowColor)"
                  : "#eee",
            }}
            onClick={() =>
              onChangeAnnouncementByType(Announcements.Type.PHILATELY.VALUE)
            }
          >
            {today.includes(Announcements.Type.PHILATELY.VALUE) ? (
              <>
                <i className="fa-solid fa-bell"></i>
                <p>{t(LanguageUid.NEW_POST)}</p>
              </>
            ) : null}
            {t(LanguageUid.ANNOUNCEMENT_PHILATELY)}
          </button>

          <button
            style={{
              backgroundColor:
                announcementType === Announcements.Type.BID.VALUE
                  ? "var(--pttYellowColor)"
                  : "#eee",
            }}
            onClick={() =>
              onChangeAnnouncementByType(Announcements.Type.BID.VALUE)
            }
          >
            {today.includes(Announcements.Type.BID.VALUE) ? (
              <>
                <i className="fa-solid fa-bell"></i>
                <p>{t(LanguageUid.NEW_POST)}</p>
              </>
            ) : null}
            {t(LanguageUid.ANNOUNCEMENT_BID)}
          </button>
        </div>
      )}
      {/* End Announcement Buttons for Filtered*/}
      {/* Start List */}
      {[
        Announcements.Type.GENERAL.VALUE,
        Announcements.Type.PHILATELY.VALUE,
        Announcements.Type.BID.VALUE,
      ].map((type, index) => (
        <div
          key={index}
          className={`${styles.body} ${
            type === announcementType ? styles.isActive : ""
          }`}
          style={{
            padding:
              screenWidth <= 790
                ? "0 0 0 0"
                : getCustomServiceIsActive
                ? "0 11rem 0 0"
                : "0 4rem 0 0",
          }}
        >
          {GetAnnouncementByTop(type, showCount)}
        </div>
      ))}
      {/* End List */}
      {/* Start More Button */}
      {!router.query.page && (
        <div className={styles.moreContainer}>
          <Link
            href={`/${viewAllUrl}?announcementType=${announcementType}${
              announcementType == Announcements.Type.BID.VALUE
                ? "&pageSize=30"
                : ""
            }&page=1`}
          >
            <button className={styles.moreButton}>
              <span>{t(LanguageUid.ANNOUNCEMENT_GET_ALL_GENERAL_BUTTON)}</span>
            </button>
          </Link>
        </div>
      )}
      {/* End More Button */}
    </section>
  );
};

const mapStateToProps = (state) => ({
  getAnnouncementCount: state.getAnnouncementCount,
  paging: state.paging,
  getCustomServiceIsActive: state.getCustomServiceIsActive,
});

const mapDispatchToProps = {
  onGetAnnouncementCount: Get_AnnouncementCount,
  onUpdatePaging: Update_Paging,
  onGetAnnouncementType: Get_AnnouncementType,
};

export default connect(mapStateToProps, mapDispatchToProps)(Announcement);
