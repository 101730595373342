import { ActionTypes } from "../../libs/enums";

const GetAction_AnnouncementCount = (count) => {
  return {
    type: ActionTypes.GET_ANNOUNCEMENT_COUNT,
    payload: count,
  };
};

export { GetAction_AnnouncementCount };
